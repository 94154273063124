/* eslint-disable no-magic-numbers */
import { useState, useEffect } from 'react';
import { FormEvent } from 'react';

import { Checkbox as MuiCheckbox } from '@mui/material';

import { PodcastSpotAPI } from 'api/podcastSpotAPI/index';
import { Form, Formik } from 'formik';

import { OutlinedButton, Input, SubmitButton, Checkbox, DatePickerGroup, Label, Select } from 'components/UIComponents';
import { FieldGroupTitleContainer, shortFieldStyles } from 'components/UIComponents/layout/styledFormComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledCheckboxContainer as CheckboxContainer } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';

import { usePopup } from 'context/GlobalPopupContext';
import { useToast } from 'context/ToastContext';

import { ReactComponent as CheckboxChecked } from 'assets/icons/CheckboxChecked.svg';
import { ReactComponent as CheckboxUnchecked } from 'assets/icons/CheckboxUnchecked.svg';

import { getResponseHandler } from 'helpers/forms';
import { podcastValidationSchema } from 'helpers/validation/podcastValidationSchema';

import { ModelName } from 'constants/enums/ModelName';
import { ToastType } from 'constants/enums/PopupType';
import { SpotType } from 'constants/enums/SpotTypes';
import { spotMessages } from 'constants/messages/spots';

import { IResponse } from 'interfaces/api';
import { IPodcastForm } from 'interfaces/spots/Podcast/IPodcastForm';

import { colors } from 'styles/globalStyles';

import DependentSelectsBlock from '../commonComponents/DependentSelectsBlock';
import RateFields from '../commonComponents/RateFields';
import { datesFieldStyles } from './styledComponents';

import { OrderAPI } from 'api/orderAPI';
import RESPONSE_CODES from '../../../../constants/responseCodes';
import { isDigitallyInsertedOptions } from '../../../../constants/outletOptions';

interface PodcastFormProps {
  onModalClose: () => void;
  successAction: () => void;
  editPodcastId: number;
  isPaid?: boolean;
}

export const pixelOptions = [
  { key: 'Required', value: 'Required' },
  { key: 'Preferred', value: 'Preferred' },
  { key: 'Not Required', value: 'Not Required' },
];

export const readOptions = [
  { key: 'Host Read', value: 'Host Read' },
  { key: 'Producer Read', value: 'Producer Read' },
  { key: 'Pre Produced', value: 'Pre Produced' },
];

export const endorsementOptions = [
  { key: 'PE Required', value: 'PE Required' },
  { key: 'PE Preferred', value: 'PE Preferred' },
  { key: 'N/A', value: 'N/A' },
];

export const positionOptions = [
  { key: 'Pre-Roll', value: 'Pre-Roll' },
  { key: 'Mid-Roll', value: 'Mid-Roll' },
  { key: 'Post-Roll', value: 'Post-Roll' },
  { key: 'Pre/Mid Rotator', value: 'Pre/Mid Rotator' },
  { key: 'Pre/Mid Combo', value: 'Pre/Mid Combo' },
];

const PodcastForm = ({ onModalClose, successAction, editPodcastId, isPaid = false }: PodcastFormProps) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const { openPopup, openSequentialPopups } = usePopup();
  const { onToastOpen } = useToast();
  const [podcastFormInformation, setPodcastFormInformation] = useState<IPodcastForm>({
    outletId: '',
    productId: '',
    creativeId: '',
    codeId: '',
    startDate: '',
    endDate: '',
    impressions: '',
    grossRate: '',
    netRate: '',
    isPixelRequired: 'Required',
    read: 'Host Read',
    endorsement: 'PE Required',
    position: 'Mid-Roll',
    isTestShow: false,
    isDigitallyInserted: 'Baked In',
  });
  const [withOrder, setWithOrder] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [createAnother, setCreateAnother] = useState(false);

  useEffect(() => {
    if (editPodcastId) {
      PodcastSpotAPI.getOne(editPodcastId).then(({ data: { data } }: IResponse) => {
        setPodcastFormInformation({
          outletId: data.outlet_id,
          outletName: data.outlet_name || '',
          productId: data.product_id,
          productName: data.product_name || '',
          creativeId: data.creative_id || '',
          creativeName: data.creative_name || '',
          codeId: data.code_id || '',
          codeName: data.code || '',
          startDate: data.start_date || '',
          endDate: data.end_date || '',
          impressions: data.impressions,
          grossRate: data.gross_rate,
          netRate: data.net_rate,
          isPixelRequired: data.is_pixel_required || 'Required',
          read: data.read || 'Host Read',
          endorsement: data.endorsement || 'PE Required',
          position: data.position || 'Mid-Roll',
          isTestShow: data.is_test_show,
          isDigitallyInserted: data.is_digitally_inserted || 'Baked In',
        });

        if (data.order_id) {
          setWithOrder(true);
          setOrderId(data.order_id);
        }
      });
    }
  }, [editPodcastId]);

  const checkOrderChange = async (podcastId: number, updater: () => Promise<void>, startDate: string) => {
    const response = await OrderAPI.checkSpotOrderChange(SpotType.podcast,
      { spot_id: editPodcastId, start_date: startDate }, orderId);

    if (response.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
      openPopup(response.data.error);
    } else {
      const messages = response.data.data.messages;
      if (Array.isArray(messages) && messages.length > 0) {
        openSequentialPopups(popupDataCustomizer(messages), async () => {
          await updater();
          await orderChange(editPodcastId, startDate, true);
        });
      } else {
        await updater();
        await orderChange(editPodcastId, startDate, false);
      }
    }
  };

  const popupDataCustomizer = (popupData: any[]) => {
    return popupData.map(data => ({
      ...data,
      closeCallback: () => {
        setIsDisabled(false);
      },
    }));
  };

  const orderChange = (podcastId: number, startDate: string, changeOrder: boolean) => {
    const orderInfo = {
          outlet_id: podcastFormInformation.outletId,
          spots_ids: [podcastId],
          type: SpotType.podcast,
          start_date: startDate,
          change: changeOrder,
        };

    OrderAPI.orderChangeAfterSpotUpdate(orderInfo, orderId).then((response: IResponse) => {
      if (response.status !== RESPONSE_CODES.CREATED) {
        openPopup(response.data.error);
      }
    });
  };

  return (
    <>
      <Formik
        initialValues={podcastFormInformation}
        validationSchema={podcastValidationSchema}
        validateOnBlur
        enableReinitialize
        onSubmit={(values, actions) => {
          const responseHandler = getResponseHandler(
            ModelName.PodcastSpot,
            () => {
              if (!createAnother) {
                onModalClose();
              }
              if (!editPodcastId) {
                onToastOpen('SPOT_CREATED', 'Spot record has been successfully created', ToastType.success);
              }
              successAction();
            },
            actions
          );

          if (editPodcastId) {
            const updater = () =>
              PodcastSpotAPI.updatePodcast(editPodcastId, values)
                .then(responseHandler)
                .finally(() => setIsDisabled(false));
            if (withOrder) {
              openPopup({
                ...spotMessages['M-152'],
                submitCallback: () => checkOrderChange(editPodcastId, updater, values.startDate),
                closeCallback: () => setIsDisabled(false),
              });
            } else {
              updater();
            }
          } else {
            PodcastSpotAPI.createPodcast(values)
              .then(responseHandler)
              .finally(() => setIsDisabled(false));
          }
        }}
      >
        {({ errors, handleSubmit, dirty, values, setFieldValue, setFieldTouched }) => {
          return (
            <Form>
              <DialogContent>
                <FieldGroupTitleContainer>PODCAST INFO</FieldGroupTitleContainer>
                <DependentSelectsBlock
                  values={values}
                  setFieldValue={setFieldValue}
                  formInformation={podcastFormInformation}
                  spotType={SpotType.podcast}
                  isEdit={!!editPodcastId}
                  isPaid={isPaid}
                />
                <FieldGroupTitleContainer>AIR PERIOD</FieldGroupTitleContainer>
                <DatePickerGroup
                  label="Air Dates"
                  startProps={{
                    name: 'startDate',
                    required: true,
                    onChange: () => {
                      if (values.endDate) {
                        setFieldTouched('endDate', true);
                      }
                    },
                  }}
                  endProps={{ name: 'endDate', minDate: values.startDate }}
                  styles={datesFieldStyles}
                />
                <FieldGroupTitleContainer>PAYMENT INFO</FieldGroupTitleContainer>
                <Input name="impressions" label="Impressions" required styles={shortFieldStyles} />
                <RateFields
                  grossRateValue={values.grossRate}
                  netRateValue={values.netRate}
                  grossRateError={errors.grossRate}
                  netRateError={errors.netRate}
                  setFieldValue={setFieldValue}
                  isPaid={isPaid}
                />
                <FieldGroupTitleContainer>ADDITIONAL INFO</FieldGroupTitleContainer>
                <Checkbox name="isTestShow" label="Test show" />
                <Select
                  name="isDigitallyInserted"
                  label="Digitaly Inserted"
                  options={isDigitallyInsertedOptions}
                  required
                />
                <Select
                  name="isPixelRequired"
                  label="Pixel"
                  options={pixelOptions}
                  required
                />
                <Select
                  name="read"
                  label="Read"
                  options={readOptions}
                  required
                />
                <Select
                  name="endorsement"
                  label="Endorsement"
                  options={endorsementOptions}
                  required
                />
                <Select
                  name="position"
                  label="Position"
                  options={positionOptions}
                  required
                />
              </DialogContent>
              <DialogActions>
                {!editPodcastId && (
                  <CheckboxContainer>
                    <MuiCheckbox
                      checked={createAnother}
                      checkedIcon={<CheckboxChecked color={colors.red} />}
                      icon={<CheckboxUnchecked />}
                      onClick={() => setCreateAnother(!createAnother)}
                      style={{ padding: '8px 9px' }}
                    />
                    <Label>Create Another</Label>
                  </CheckboxContainer>
                )}
                <OutlinedButton onClick={onModalClose}>Cancel</OutlinedButton>
                <SubmitButton
                  disabled={!dirty || !!Object.values(errors).length || isDisabled}
                  onClick={(values: FormEvent<HTMLFormElement>) => {
                    setIsDisabled(true);
                    handleSubmit(values);
                  }}
                >
                  {editPodcastId ? 'Save' : 'Add'}
                </SubmitButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default PodcastForm;
